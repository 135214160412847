import React, { useState, useEffect } from "react"
import moment from "moment"
import Layout from "../components/Layout"
import styles from "./episodes.module.less"
import getYouTubeID from "get-youtube-id"
import sortPodcasts from "../utils/sortPodcasts"

const per_page = 5

const EpisodesPage = ({ data }) => {
  const [page, setPage] = useState(1)

  const [podcasts, setPodcasts] = useState([])
  const pageData = data.pageData.nodes[0]

  useEffect(() => {
    setPodcasts(sortPodcasts(data.podcasts.edges))
  }, [data.podcasts.edges])

  const loadMore = () => {
    if (data.podcasts.edges.length > page * per_page) {
      setPage(page + 1)
    }
  }

  return (
    <Layout
      meta={{
        title: pageData.metaTitle,
        description: pageData.metaDescription.metaDescription,
        image: pageData.metaImage.localFile.publicURL,
      }}
    >
      <div className="container">
        <div className={"page_title podcasts"}>
          <h1>{pageData.title}</h1>
          <p>{pageData.subtitle}</p>
        </div>
        {podcasts.slice(0, page * per_page).map((episode, idx) => {
          return <Podcast episode={episode} key={idx} />
        })}

        {data.podcasts.edges.length > per_page && (
          <div className="row">
            <div className="col text-center">
              <button
                className={`btn btn-primary ${styles.load_more}`}
                onClick={loadMore}
                disabled={data.podcasts.edges.length <= page * per_page}
              >
                {data.podcasts.edges.length <= page * per_page
                  ? "No more episodes"
                  : "More Episodes"}
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default EpisodesPage

const Podcast = ({
  episode: {
    node: {
      title,
      description: { description },
      postDate,
      immigrantsName,
      youTubeVideoLink,
    },
  },
}) => {
  return (
    <a
      href={youTubeVideoLink}
      target={"_blank"}
      rel={"noreferrer"}
      className={styles.podcast_block}
    >
      <div className={"row"}>
        <div className="col-sm-12 col-md-5">
          <div className={"youtube-video my-0"}>
            <iframe
              src={`https://www.youtube-nocookie.com/embed/${getYouTubeID(
                youTubeVideoLink
              )}?rel=0`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title={""}
              allowFullScreen
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-7">
          <p className={styles.author}>{immigrantsName}</p>
          <h2>{title}</h2>
          <p className={styles.date}>
            {moment(postDate).format("MMMM DD, YYYY")}
          </p>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </a>
  )
}

export const query = graphql`
  query {
    pageData: allContentfulEpisodesPage {
      nodes {
        title
        subtitle
        metaTitle
        metaDescription {
          metaDescription
        }
        metaImage {
          localFile {
            publicURL
          }
        }
      }
    }
    podcasts: allContentfulPodcast {
      edges {
        node {
          title
          description {
            description
          }
          slug
          immigrantsName
          postDate
          youTubeVideoLink
        }
      }
    }
  }
`
